import { Grid, Typography } from '@mui/material';
import { blue, brown, green, orange, red, yellow } from '@mui/material/colors';
import { ContactSupportOutlined } from '@mui/icons-material';
import React, { useEffect, useMemo } from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { EnhancedTable } from '../../../components/general/EnhancedTable';
import { TypographyStyled } from '../../../components/general/mini_components';
import { ColourChip } from '../../../components/general/mini_components-ts';
import Loader from '../../../components/Loader';
import { ISurvey } from '../../../constants/types';
import { useDepartments } from '../../../hooks';
import { dateAndTimeFromPostgresString, decryptSinglePatientData, fullDateFromPostgresString, getDepartmentFromUnit, patientFullName, researcherFullName, stringDatePostgresToDate } from '../../../utils/index.jsx';
import axios from '../../../utils/axios';
import { useRequests } from '../../../hooks/useRequests';
import { useSurgeryAppointments } from '../../../hooks/useSurgeryAppointments';

import { IRequest, IRequestServiceInvestigation, IServiceInvestigation, RequestStatus, RequestType, ServiceType } from './types';
import SearchBox from '../../../components/general/SearchBox';

const requestGroupStatus = (request:IRequest) => {
    // Si una request es completada, el estado es completada. Si no, devuelvo los estados de las requests
    if(request.requestsServiceInvestigation.some(request => request.status === RequestStatus.COMPLETED)){
        return <RequestStatusToChip status={RequestStatus.COMPLETED} />
    }
    else{
        return request.requestsServiceInvestigation.map((req) => {
            return <RequestStatusToChip status={req.status} />
        }) 
    }
    
}

export const statusToColor = (status:RequestStatus) => {
    let colour = "#000";
    switch(status){
        case RequestStatus.PENDING_APPROVAL:
        case RequestStatus.PENDING_PAYMENT:
        case RequestStatus.IN_PROGRESS:
            colour = yellow[900];
            break;
        case RequestStatus.ACCEPTED: 
            colour = blue[900];
            break;
        case RequestStatus.SOME_ACCEPTED:
        case RequestStatus.INCOMPLETE_ACCEPTED:
            colour = blue[600];
            break;
        case RequestStatus.COMPLETED: 
            colour = green[700];            
            break;
        case RequestStatus.CANCELED:
        case RequestStatus.DENIED:
        case RequestStatus.INCOMPLETE:
            colour = red[500];            
            break;  
    }
    return colour;
}

export const RequestStatusToChip:React.FC<{status:RequestStatus}> = ({status}) =>  {
        let colour = statusToColor(status);
        let translation = RequestStatus[status]
       
        return <ColourChip rgbcolor={colour} label={<Translate id={`pages.hospital.services.request_status.${translation}`}  />}/>
}

export const serviceToColor = (type:ServiceType) => {
    let colour = null
    switch(type){
        case ServiceType.LABORATORY:
            colour = orange[900];
            break;
        case ServiceType.IMAGING:
            colour = yellow[900];
            break;
        case ServiceType.PHARMACY:
            colour = green[900];
            break;
        case ServiceType.SHOE:
            colour = brown[900];
            break;
        case ServiceType.INTERCONSULT:
            colour = red[100];
            break;
    }
    return colour;
}

const ServiceTypeToChip:React.FC<{type:ServiceType}> = ({type}) =>  {
    let colour = serviceToColor(type);
    let translation = ServiceType[type];
    return <ColourChip rgbcolor={colour} label={<Translate id={`pages.hospital.services.service_type.${translation}`}/>} />
}


interface RequestTableProps {
    serviceType:number,
    typeRequest:RequestType,
    uuidPatient?:string,
    noSearchBox?:boolean,
    uuidInvestigation:string,
    showActions?:boolean,
    surveys?:ISurvey[],
    searchFor:string,
    fillPending?:boolean,
    encryptionData?:{
        encryptedKeyUsed:number,
        keyResearcherInvestigation:string,
        permissions:any[],
        personalFields:any[],
    },
    callBackRequestSelected:(request:IRequest)=>void,
    createAppointmentFromRequestCallback:(requestId:number) => void,
    goToAppointmentFromRequestCallback:(uuidAppointment:string) => void
}

type RequestTablePharmacyProps = Pick<RequestTableProps, 'serviceType' | 'uuidInvestigation' | 'callBackRequestSelected'>

const ACTIONABLE_REQUESTS = [RequestStatus.PENDING_APPROVAL, RequestStatus.ACCEPTED, RequestStatus.COMPLETED, RequestStatus.IN_PROGRESS, RequestStatus.PENDING_ANESTHESIOLOGIST, RequestStatus.PENDING_PURCHASE_SURGERY_PROTHESIS];

export const RequestTableService:React.FC<RequestTableProps> = ({serviceType, uuidPatient, uuidInvestigation, showActions, 
                                                                    surveys, fillPending, encryptionData, callBackRequestSelected, 
                                                                    createAppointmentFromRequestCallback, goToAppointmentFromRequestCallback}) => {
    
    return <RequestTable serviceType={serviceType} uuidPatient={uuidPatient} searchFor="general.patient"
                uuidInvestigation={uuidInvestigation} showActions={showActions} surveys={surveys} typeRequest={RequestType.SERVICES}
                fillPending={fillPending} encryptionData={encryptionData} 
                createAppointmentFromRequestCallback={createAppointmentFromRequestCallback}
                goToAppointmentFromRequestCallback={goToAppointmentFromRequestCallback}
                callBackRequestSelected={callBackRequestSelected} />
}

export const RequestTablePharmacy:React.FC<RequestTablePharmacyProps> = ({serviceType, uuidInvestigation, callBackRequestSelected}) => {
    return <RequestTable serviceType={serviceType} searchFor="hospital.search_box.staff" typeRequest={RequestType.PHARMACY}
                uuidInvestigation={uuidInvestigation} 
                callBackRequestSelected={callBackRequestSelected} />
}

const RequestTable: React.FC<RequestTableProps> = ({
    typeRequest, 
    serviceType,
    searchFor, 
    noSearchBox, 
    surveys, 
    uuidPatient, 
    uuidInvestigation, 
    encryptionData, 
    showActions,
    fillPending,  
    callBackRequestSelected, 
    createAppointmentFromRequestCallback,
    goToAppointmentFromRequestCallback
}) => {
    const { 
        requests, 
        loading: requestsLoading, 
        error: requestsError 
    } = useRequests({
        serviceType,
        uuidPatient,
        uuidInvestigation,
        typeRequest
    });

    const { 
        appointmentsByRequest, 
        loading: appointmentsLoading, 
        error: appointmentsError 
    } = useSurgeryAppointments({
        uuidInvestigation,
        enabled: serviceType === ServiceType.SURGERY
    });

    const enrichedRequests = useMemo(() => {
        if (!requests) return [];

        if(appointmentsByRequest.length === 0){
            return requests;
        }
        
        return requests.map(request => {
            const appointmentWithRequest = appointmentsByRequest[request.id];
            return {
                ...request,
                appointment: appointmentWithRequest ? appointmentWithRequest : null
            }
        });
    }, [requests, appointmentsByRequest]);


    if (requestsLoading || appointmentsLoading || !requests) {
        return <Loader />;
    }

    if (requestsError || appointmentsError) {
        console.error('Errors:', { requestsError, appointmentsError });
        // You might want to show an error message to the user
    }

    if (requests.length > 0) {
        return (
            <RequestTableComponent 
                searchFor={searchFor} 
                noSearchBox={noSearchBox} 
                serviceType={serviceType} 
                surveys={surveys} 
                fillPending={fillPending}  
                encryptionData={encryptionData} 
                uuidPatient={uuidPatient} 
                showActions={showActions}
                loading={requestsLoading || appointmentsLoading} 
                requests={enrichedRequests} 
                callBackRequestSelected={callBackRequestSelected}
                createAppointmentFromRequestCallback={createAppointmentFromRequestCallback}
                goToAppointmentFromRequestCallback={goToAppointmentFromRequestCallback}
            />
        );
    }

    return (
        <TypographyStyled variant="body2">
            <Translate id="pages.hospital.services.no_requests" />
        </TypographyStyled>
    );
};

export default RequestTable;

interface RequestTableComponentProps extends Omit< RequestTableProps, 'uuidInvestigation' >{
    requests:IRequest[],
    loading:boolean,
    createAppointmentFromRequestCallback:(requestId:number) => void,
    goToAppointmentFromRequestCallback:(uuidAppointment:string) => void
}

interface Row {
    id: number;
    nhc: string | number;
    service: string | JSX.Element;
    department: string;
    patient: string;
    researcher: string;
    status: JSX.Element;
    statusValue: RequestStatus;
    type: string | JSX.Element;
    date: string;
}
export const RequestTableComponent: React.FC<RequestTableComponentProps> = ({ uuidPatient, searchFor, requests, serviceType, noSearchBox,
                                                                                encryptionData, loading, callBackRequestSelected, createAppointmentFromRequestCallback, 
                                                                                goToAppointmentFromRequestCallback }) => {
    
    
    const {departments} = useDepartments();    
    const [rows, setRows] = React.useState<Row[]>([]);
    const [filteredRows, setFilteredRows] = React.useState<Row[]>([]);
    const [searchText, setSearchText] = React.useState("");
    const [statusFilter, setStatusFilter] = React.useState<RequestStatus[]>([]);

    useEffect(() => {
        setFilteredRows(rows.filter((row: Row) => {
            const searchItem = serviceType === RequestType.PHARMACY ? row.researcher : row.patient;
            return (searchItem.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) && (statusFilter.includes(row.status.props.status)))
                        
        }));
        
    }, [searchText, statusFilter]);
    
    useEffect(() => {
        const rows = requests.sort((reqA, reqB) => stringDatePostgresToDate(reqB.updatedAt).getTime() - stringDatePostgresToDate(reqA.updatedAt).getTime() ).map((request) => {            
            return {
                id: request.id,
                nhc: request.requestsServiceInvestigation[0] ? request.requestsServiceInvestigation[0].patientInvestigation.id : "",
                service: request.requestsServiceInvestigation[0] ? request.requestsServiceInvestigation.length > 1 ? <ColourChip rgbcolor={serviceToColor(request.type)} label={<Translate id="general.several" />} /> : <ColourChip rgbcolor={serviceToColor(request.type)} label={request.requestsServiceInvestigation[0].serviceInvestigation.service.name} /> : "",
                department:request.departmentRequest ? request.departmentRequest.name : "",
                patient:request.requestsServiceInvestigation[0] ? request.requestsServiceInvestigation[0].patientInvestigation.personalData ? patientFullName(decryptSinglePatientData(request.requestsServiceInvestigation[0].patientInvestigation.personalData, encryptionData)) : request.requestsServiceInvestigation[0].patientInvestigation.id.toLocaleString() : "",
                researcher: researcherFullName(request.researcher),            
                status: <RequestStatusToChip status={request.status} />,
                statusValue: request.status,
                type : request.requestsServiceInvestigation[0] ? <ServiceTypeToChip type={request.requestsServiceInvestigation[0].serviceInvestigation.service.type} /> : "", 
                date: dateAndTimeFromPostgresString("es", request.updatedAt),
            }
        })
        setRows(rows);
        setFilteredRows(rows);
    }, [requests]);

    function applyStatusFilter(status:RequestStatus){
        if(statusFilter && statusFilter.includes(status)){
            if(statusFilter.length === 1){
                setStatusFilter([]);
            }
            else{
                setStatusFilter(statusFilter.filter((s) => s !== status));
            }
        }
        else if(statusFilter){
            setStatusFilter([...statusFilter, status]);
        }
        else{
            setStatusFilter([status]);
        }
    }

    function handleAppointmentClick(id:number){
        const request = requests.find((req) => req.id === id);
        if(!request){
            console.log("No se encontró la solicitud");
        }
        else if(request.appointment){
            console.log("Ya existe una cita para esta solicitud");
            goToAppointmentFromRequestCallback(request.appointment.uuid);
        }
        else{
            createAppointmentFromRequestCallback(id)
        }
    }
    function fillRequest(id:number){
        const request = requests.find((req) => req.id === id);
        if(request){
            const hasActionableRequests = ACTIONABLE_REQUESTS.filter(function(status) {
                if(serviceType === RequestType.PHARMACY){
                    return status === request.status
                }
                return request.requestsServiceInvestigation.findIndex((req) => req.status === status) !== -1
            }).length > 0;
            if(hasActionableRequests){
                callBackRequestSelected(request);
            }
            else{
                console.log("No hay survey");
            }
        }
        else{
            console.log("No hay nada que hacer con esa solicitud");
        }
        
    }
    if(loading || !departments){
        return <Loader />
    }
    else if(requests.length === 0){
        return(
            <div className="text-center">
                <h4><Translate id="pages.hospital.services.no_requests" /></h4>
            </div>
        )
    }
    let headCells;
    if(serviceType === RequestType.PHARMACY){
        headCells = [
            { id: 'id', numeric: false, disablePadding: false, label: 'ID' },
            {id : 'researcher', label: <Translate id="hospital.staff" />, alignment:'left'},
            {id : 'status', label: <Translate id="pages.hospital.services.status" />, alignment:'left'},
            {id : 'department', label: <Translate id="hospital.departments.department" />, alignment:'left'},
            {id : 'date', label: <Translate id="general.date" />, alignment:'left'},
        ];
    }
    else{
        headCells = [{id : 'id', label: 'Request ID', alignment:'left'},
                    {id : 'researcher', label: <Translate id="hospital.staff" />, alignment:'left'},
                    {id : 'department', label: <Translate id="hospital.departments.department" />, alignment:'left'},
                    {id : 'service', label: <Translate id="pages.hospital.services.service" />, alignment:'left'},
                    {id : 'type', label: <Translate id="pages.hospital.services.type" />, alignment:'left'},
                    {id : 'date', label: <Translate id="general.date" />, alignment:'left'},
                    {id : 'status', label: <Translate id="pages.hospital.services.status" />, alignment:'left'},];
        if(!uuidPatient){
            headCells.splice(headCells.findIndex((head) => head.id === 'type'), 1); 
            headCells.splice(1, 0, {id : 'nhc', label: <Translate id="investigation.create.personal_data.fields.health_id" />, alignment:'left'})
            headCells.splice(2, 0, {id : 'patient', label: <Translate id="general.patient" />, alignment:'left'})
        }
    }
     
    let actions = null;
    if(serviceType === ServiceType.SURGERY){
        actions = [{type : "appointment", func : (id:number) => handleAppointmentClick(id), check: (row:Row) => row.statusValue !== RequestStatus.CANCELED}]
    }
    return (
        <Grid container spacing={2}>
            {
                !noSearchBox && 
                <SearchBox textField={{label:searchFor, callBack:setSearchText}}
                    activeFilters={statusFilter}
                    filterItems={[
                        {label:"completed", value:RequestStatus.COMPLETED, color:statusToColor(RequestStatus.COMPLETED), callBack:() => applyStatusFilter(RequestStatus.COMPLETED)},
                        {label:"in_progress", value: RequestStatus.IN_PROGRESS, color:statusToColor(RequestStatus.IN_PROGRESS), callBack:() => applyStatusFilter(RequestStatus.IN_PROGRESS)},
                        {label:"accepted", value:RequestStatus.ACCEPTED, color:statusToColor(RequestStatus.ACCEPTED), callBack:() => applyStatusFilter(RequestStatus.ACCEPTED)},
                        {label:"some_accepted", value:RequestStatus.SOME_ACCEPTED, color:statusToColor(RequestStatus.SOME_ACCEPTED), callBack:() => applyStatusFilter(RequestStatus.SOME_ACCEPTED)},
                        {label:"pending_payment", value:RequestStatus.PENDING_PAYMENT, color:statusToColor(RequestStatus.PENDING_PAYMENT), callBack:() => applyStatusFilter(RequestStatus.PENDING_PAYMENT)},
                    ]} />
            }
            <Grid item xs={12}>
                <EnhancedTable  selectRow={(id:number) => fillRequest(id)} noHeader noSelectable 
                    rows={filteredRows} headCells={headCells} actions={actions}
                     />  
            </Grid>
        </Grid>
    );
};
